import { Component, EventEmitter, HostListener, Inject, OnInit, Output } from '@angular/core';
import { caughtError, SearchQuery, SearchService } from '@lobos/library';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject } from 'rxjs';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { Article, MultiIndexSearchResponse } from '@lobos/common';
import { CmsService } from '../../services/cms/cms.service';
import { CmsSearch } from 'src/app/services/cms/interfaces/cms-search.interface';
import { environment } from '../../../environments/environment';
import { TranslocoService } from '@ngneat/transloco';

@UntilDestroy()
@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent implements OnInit {
  @Output()
  searchIsOpen = new EventEmitter<boolean>();

  @Output()
  searchIsFocused = new EventEmitter<boolean>();

  @Output()
  searchResultClick = new EventEmitter<boolean>();

  public searchTerm: string = '';
  public search$: ReplaySubject<string> = new ReplaySubject<string>();
  public shopSearchResult: MultiIndexSearchResponse | undefined;
  public cmsSearch$: ReplaySubject<string> = new ReplaySubject<string>();
  public cmsSearchResult: CmsSearch | undefined;
  public articles$: Observable<Article[]> = this.searchQuery.articles$.pipe(untilDestroyed(this));
  public articles: Article[] | undefined;
  public totalResults = 0;
  public totalCmsResults = 0;
  public currentLanguage: string = 'de';
  private wasInside: boolean = false;

  constructor(
    private searchService: SearchService,
    private cmsService: CmsService,
    private searchQuery: SearchQuery,
    private transloco: TranslocoService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.search$
      .asObservable()
      .pipe(
        debounceTime(200),
        tap((searchString: string) => {
          this.searchIsOpen.emit(true);
          if (searchString.length < 2) {
            this.searchService.resetStore();
          }
        }),
        filter((searchString) => searchString.length > 1),
        switchMap((searchString) =>
          this.searchService.search(encodeURIComponent(searchString)).pipe(
            tap((searchResult) => {
              this.shopSearchResult = searchResult;
              this.totalResults = searchResult.articleTotal + (this.cmsSearchResult?.hits?.total || 0);
            }),
            caughtError({ label: 'SearchBoxComponent.constructor()' }),
          ),
        ),
        untilDestroyed(this),
      )
      .subscribe();

    /*    this.cmsSearch$
          .asObservable()
          .pipe(
            debounceTime(200),
            filter((searchString: string) => searchString.length > 2),
            switchMap((searchString: string) =>
              this.cmsService.searchCms(searchString).pipe(
                tap((searchResult) => {
                  this.cmsSearchResult = searchResult;
                  this.totalCmsResults = searchResult.hits.total;
                  this.totalResults = searchResult.hits.total + (this.shopSearchResult?.articleTotal || 0);
                }),
              ),
            ),
            untilDestroyed(this),
          )
          .subscribe();*/
  }

  ngOnInit() {
    this.articles$.subscribe((articles) => {
      this.articles = articles;
    });

    this.currentLanguage = this.transloco.getActiveLang();
  }

  @HostListener('click')
  clickedInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.searchIsOpen.emit(false);
    }
    this.wasInside = false;
  }

  public search(): void {
    if (this.searchTerm.length >= 2) {
      this.searchIsOpen.emit(true);
      this.search$.next(this.searchTerm);
      this.cmsSearch$.next(this.searchTerm);
    } else {
      this.searchIsOpen.emit(false);
    }
  }

  closeOnClick(e: Event) {
    e.stopPropagation();
    this.searchIsOpen.emit(false);
    this.searchResultClick.emit(true);
  }

  clearSearch() {
    this.searchIsOpen.emit(false);
    this.searchIsFocused.emit(false);
  }

  protected readonly environment = environment;
}
